import { Box, Button, Table, TableBody, td, TableContainer, tr, Typography } from '@mui/material';
import { TD_FIRST, TD_FIRST_PENDING_REQUEST, TD_LAST, TD_LAST_PENDING_REQUEST, TD_NORMAL, TD_NORMAL_PENDING_REQUEST, THEME_COLOR, THEME_HIGHLIGHT } from "../../constants/style";
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import ContentLayout from '../../components/shared-content-layout';
import VmTable from '../../components/shared-table';
import useStores from '../../hooks/use-stores';
import { PAGE_SIZE } from '../../constants/options';
import { toJS } from 'mobx';
import { DateUtils } from '../../utilities/date';
import VmModal from '../../components/shared-modal';
import ChildNav from '../../components/shared-child-nav';

const MessageOverviewPage = observer(() => {

    const { messageStore, userStore } = useStores();
    const [page, setPage] = useState<number>(1);
    const [messageModal, setMessageModal] = useState<boolean>(false);
    const [selectedMessage, setSelectedMessage] = useState<string>("");


    // view detail
    const [viewDetailPage, setViewDetailPage] = useState<boolean>(false);

    const handleMessageModal = (detail: string, id: number) => {
        messageStore.readMessage(id).then(() => {
            messageStore.messageList.data.find((message: any) => message.id === id);
            messageStore.getNewMessageNumber();
            messageStore.messageList.data = messageStore.messageList.data.map((message: any) => {
                if (message.id === id) {
                    if (!message.isRead) {
                        userStore.msgNumber -= 1;
                        return { ...message, isRead: true };
                    }
                }
                return message;
            });
        })

        setSelectedMessage(detail);
        setMessageModal(true);
    }

    useEffect(() => {
        if (messageStore.messageList.totalCount === 0) {
            messageStore.getMessageList(PAGE_SIZE, page - 1);
        }
    }, [])




    return (

        <ContentLayout
            pageName='Message'
            pageHeading='Message Overview'
            breadCrumb={[
                // { label: t('STUDENT_PROFILE'), to: "" },
                // { label: t('VISIT_HISTORY'), to: "/visit" }
            ]}
            showHeadingButton={false}
        >

            <VmTable
                loading={messageStore.loadingMessageList}
                page={page}
                paginationCount={messageStore.messageList.totalCount == 0 ? 1 : messageStore.messageList.totalCount / PAGE_SIZE}
                onChangePagination={(event, pageNumber) => {
                    setPage(pageNumber);
                    messageStore.getMessageList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE)
                }}
                thead={["ID", 'Message', 'Type', 'Sender', 'Reciever', 'Sent Time', 'Action']}
                tbody={messageStore.messageList &&
                    messageStore.messageList.totalCount > 0 ?
                    messageStore.messageList.data.map((message: any, index: number) =>
                        <>
                            <Box marginY={1} />
                            <tr key={index}>
                                <td className={TD_FIRST}>{messageStore.messageList.totalCount - index}</td>
                                <td className={TD_NORMAL}>{message.title}</td>
                                <td className={TD_NORMAL}>{message.targetType}</td>
                                <td className={TD_NORMAL}>{message.senderType}</td>
                                <td className={TD_NORMAL}>{message.receiverType}</td>
                                <td className={TD_NORMAL}>{DateUtils.onConvertDateFormat(message.createTime.split("T")[0]) + " " + message.createTime.split("T")[1]}</td>
                                <td className={TD_LAST}>
                                    <Button color={message.isRead ? "primary" : "error"} variant="outlined"
                                        onClick={() => {
                                            handleMessageModal(message.detail, message.id);
                                        }}>
                                        {message.isRead ? "View Detail" : "View Detail (Unread)"}
                                    </Button>
                                </td>
                            </tr>
                            <Box marginY={1} />
                        </>
                    )

                    : <tr><td colSpan={7} style={{ textAlign: "center" }}>No Message Found</td></tr>}
            />

            <VmModal
                openModal={messageModal}
                onClose={() => {
                    setMessageModal(false)
                    setSelectedMessage("")
                }}
                title={"Message Detail"}
                width={800}
                showButton={false}
            >
                {selectedMessage !== "" &&
                    <div className="max-h-96 overflow-auto" dangerouslySetInnerHTML={{ __html: selectedMessage }} style={{ marginTop: -10 }} />}
            </VmModal>



        </ContentLayout>

    )
});

export default MessageOverviewPage;